<template>
    <div>
        <b-modal ref="create-personal-question" size="lg" id="create-personal-question" @hidden="resetModal" :no-close-on-backdrop="true">

            <template #modal-title>
                <span>{{!isEdit ? 'Создать персональный вопрос' : 'Изменить персональный вопрос'}}</span>
            </template>

            <template>
                <div class="question-message-wrapper textarea-wrapper" :class="{'invalid': submitted && !question.message.length}">
                    <label for="question-message"></label>
                    <textarea name="question-message" id="question-message" cols="30" rows="10" placeholder="Введите текст вопроса" v-model="question.message"></textarea>
                </div>
                <!--<div class="divider gray-bg-row">
                    <span class="tips-for-question">Здесь о том, как важно правильно задать правильный вопрос</span>
                </div>-->
                <div class="list-answers-wrapper">
                    <ul :class="{'invalid': submitted && question.answers.filter(o => {return o.value.length}).length < 2 }">
                        <li v-for="(answer, index) in question.answers" @mouseover="">
                            <span class="letter_index" v-if="answer.value">{{answer.letter_index}}:</span>
                            <input type="text" :class="{'active': answer.value}" v-bind:placeholder="answer.placeholder" v-model="answer.value">
                            <button @click="deleteAnswerOption(index)" class="delete-answer-option" v-if="question.answers.length >= 3">Удалить вариант</button>
                        </li>
                    </ul>
                </div>
                <div class="divider gray-bg-row">
                    <span class="gray-bg-title add-answer" v-on:click="addNewAnswer">Добавить вариант</span>
                </div>
                <div class="topics-wrapper" @click="toggleTopicsDropdown($event, true)" v-click-outside="toggleTopicsDropdown" :class="{'invalid': submitted && !question.topics.length}">
                    <div class="add-topics-btn" v-if="!question.topics.length">
                        Теги
                    </div>
                    <div class="selected-topics"  v-if="question.topics.length">
                        <span v-for="t in question.topics">{{t.name}}</span>
                    </div>
                    <div class="topics-dropdown" v-if="topics.show">
                        <div class="topic" v-for="t in topics.list" @click="$event.stopPropagation(); $event.preventDefault(); selectTopic(t)" :class="{'selected': t.selected}">
                            {{t.name}}
                        </div>
                    </div>
                </div>
                <div class="users-wrapper" @click="showUsersModal()" :class="{'invalid': submitted && !question.users.length && question.visible_for_id === 2}">
                    <span class="add-users-btn">Задать вопрос кому-то конкретному</span>
                    <div class="selected-users-wrapper" v-if="question.users.length">
                        <div class="user" v-for="u in question.users">
                            <!--<div class="avatar-wrapper image-as-image" :style="{'background-image': (u.avatar_parsed) ? 'url(' + u.avatar_parsed + ')' : 'url(' + require('../../assets/img/no-avatar.jpg') + ')' }"></div>-->
                            <avatar :width="36" :height="36" :img="u.avatar_parsed"></avatar>
                            <div class="name">{{u.name}}</div>
                        </div>
                    </div>
                </div>

                <div class="divider gray-bg-row">
                    <span class="gray-bg-title">Добавить вступительное слово</span>
                </div>
                <div class="question-description-wrapper textarea-wrapper">
                    <label for="introduction_word"></label>
                    <textarea name="introduction_word" id="introduction_word" cols="30" rows="10" placeholder="Введите текст для вступительного слова" v-model="question.introduction_word"></textarea>
                </div>
            </template>

            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <div class="foo-left-col">
                    <b-button :disabled="submitted" size="sm" variant="primary" @click="submitModal()">
                        {{!isEdit ? 'Опубликовать' : 'Сохранить'}}
                    </b-button>
                    <div class="visible_for_id-wrapper">
                        <span class="text">Вопрос виден:</span>
                        <visibility-item-select :visibilityType="question.visible_for_id" class="def-icon" @updateType="onVisibilityChange" :custom-visibility-list="customVisibilityList"></visibility-item-select>
                    </div>
                </div>
                <div class="comment-option">
                    <label for="comment_option" class="checkbox-container">
                        <input type="checkbox" class='custom-checkbox' id="comment_option" v-model="question.comment_option">
                        <span class="checkmark"></span>
                        Возможность комментировать
                    </label>
	                <label for="public-access-option" class="checkbox-container">
		                <input type="checkbox" class='custom-checkbox' id="public-access-option" v-model="question.public_access" @change="showPublicAccessAlert()">
		                <span class="checkmark"></span>
		                Разрешаю включить вопрос в общедоступный список
	                </label>
                </div>
            </template>
	        
	        <custom-modal ref="public-access-modal"></custom-modal>
        </b-modal>
        <b-modal ref="add-users" size="md" id="add-users" hide-footer :no-close-on-backdrop="true" title="Кому задать этот вопрос?" @hidden="resetUsersModal">
            <div class="question-wrapper">
                <p v-if="question.message">{{question.message}}</p>
                <p v-else>Здесь будет ваш вопрос</p>
            </div>
            <search-filter :show-error="true" :placeholderText="'Найти пользователей'" :items="usersList.list" @filtered="filteredList" class="search-filter" :is-server-side="true"></search-filter>
            <div class="users-list-wrapper" :class="{'loading': usersList.loading}">
                <!--<img src="../../../assets/img/Infinity-1s-200px.svg" alt="loading" style="width: 100px" v-if="usersList.loading">-->
                <div class="users-list asked-users" v-if="usersList.list.length">
                    <div class="user" v-for="u in usersList.list" @click="selectUser(u)" :class="{'selected': u.selected}" v-if="u.filtered">
                        <avatar :width="36" :height="36" :img="u.avatar_parsed"></avatar>
                        <div class="name">{{u.name}}</div>
                        <img v-if="u.selected" class="control-icons" src="../../../assets/img/check_new.svg" alt="delete">
                    </div>
	                <c_button v-if="usersList.list.length && (usersList.page !== usersList.last_page)" :c_loading="usersList.loading" c_class="def-btn" c_text="Загрузить еще" @clickEvent="getUsersForAskPersonalQuestion()"></c_button>
                </div>
                <!--<div class="users-list chat-users" v-if="!usersList.loading && usersList.chat_users.length">
                    <div class="divider">
                        Последние, с кем вы общались:
                    </div>
                    <div class="user" v-for="u in usersList.chat_users" @click="selectUser(u)" :class="{'selected': u.selected}" v-if="u.filtered">
                        <avatar :width="36" :height="36" :img="u.avatar_parsed"></avatar>
                        <div class="name">{{u.name}}</div>
                        <img v-if="u.selected" class="control-icons" src="../../../assets/img/check_new.svg" alt="delete">
                    </div>
                </div>-->
            </div>

            <div class="modal-controls" v-if="usersList.list.length">
                <button v-if="isSelected().length || question.users.length" class="add-users" @click="addUsersToQuestion()">
                    <span v-if="isSelected().length">Добавить <!--{{isSelected().length}}--></span>
                    <span v-else-if="!isSelected().length && question.users.length">Подтвердить</span>
                </button>
                <button class="add-users" @click="addUsersToQuestion(true)">
                    <span v-if="!isSelected().length || (isSelected().length && isSelected().length !== usersList.list.length)">Добавить всех</span>
                    <span v-else-if="isSelected().length > 0 && isSelected().length === usersList.list.length">Убрать всех</span>
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import multiselect from "../Multiselect";
    import visibilityItemSelect from "../VisibilityItemSelect";
    import searchFilter from '../SearchFilter';
    import {questionsService} from "@/_api";
    import {handleAWSImage} from "@/_helpers/aws-handler";
    import avatar from "../Avatar";
    import Vue from 'vue';
    import EventBus from "../../../_helpers/event-bus";
    import C_button from "@/components/_shared/Button";
    import CustomModal from "@/components/_shared/modals/_CustomModal";

    export default {
        name: 'createPersonalQuestionModal',
        data() {
            return {
                submitted: false,
                question: {
                    message: '',
                    answers: [],
                    topics: [],
                    users: [],
                    introduction_word: '',
                    visible_for_id: 1,
                    comment_option: true,
	                public_access: true
                },
                usersList: {
                    asked_users: [],
                    chat_users: [],
                    loading: false,
	                list: [],
	                page: 0,
	                last_page: 0,
	                search_str: ''
                },
                topics: {
                    show: false,
                    list: []
                },
                alphabet: [...Array(26).keys()].map(i => String.fromCharCode(i + 97).toUpperCase()),
                searchStr: '',
                showDropdown: false,
                isEdit: false
            }
        },
        props: {
            customVisibilityList: Array
        },
        components: {
	        CustomModal,
	        C_button,
            multiselect,
            visibilityItemSelect,
            searchFilter,
            avatar
        },
        beforeDestroy() {
            EventBus.$off('editPQ');
        },
        methods: {
            editListener() {
                // this.$on('editPQ', (data) => {
                EventBus.$on('editPQ', (data) => {
                    for (let a = 0; a < data.answers.length; a++) {
                        data.answers[a]['letter_index'] = this.alphabet[a];
                        data.answers[a]['placeholder'] = 'Введите вариант ответа ' + this.alphabet[a];
                        data.answers[a]['value'] = data.answers[a]['name'];
                    }
                    data.topics.forEach((obj) => {
                        this.topics.list.forEach((o) => {
                            if (obj.id === o.id) {
                                this.selectTopic(o);
                            }
                        });
                    });
                    console.log(data);
                    this.question = {
                        message: data.name,
                        answers: data.answers,
                        topics: [...data.topics],
                        users: [],
                        introduction_word: data.description,
                        visible_for_id: (data.visible_for_id) ? data.visible_for_id : 1,
                        comment_option: data.is_comment_enabled,
                        question_id: data.question_id,
	                    public_access: data.public_access
                    };
                    this.isEdit = true;
                    this.showModal();
                })
            },
            showModal() {
                this.getUsersForAskPersonalQuestion();
                this.$refs['create-personal-question'].show();
            },
            showUsersModal() {
                this.usersList = {
                    asked_users: [],
                    chat_users: [],
	                list: [],
	                page: 0,
	                search_str: '',
                    loading: false
                };
                this.getUsersForAskPersonalQuestion();
                this.$refs['add-users'].show();
            },
            resetModal() {
                this.question = {
                    message: '',
                    answers: [],
                    topics: [],
                    users: [],
                    introduction_word: '',
                    visible_for_id: 1,
                    comment_option: true,
	                public_access: true
                };
                this.isEdit = false;
                this.topics.list.forEach((o) => {
                    o.selected = false;
                });

                this.createAnswersArr();
            },
            resetUsersModal() {
                this.usersList = {
                    asked_users: [],
                    chat_users: [],
                    loading: false,
	                list: [],
	                page: 0,
	                search_str: '',
                }
            },
            addNewAnswer() {
                let index = this.question.answers.length;
                this.question.answers.push({
                    letter_index: this.alphabet[index],
                    placeholder: 'Введите вариант ответа ' + this.alphabet[index],
                    value: ''
                });
            },
            deleteAnswerOption(index) {
                this.question.answers.splice(index, 1);
                for (let a = 0; a < this.question.answers.length; a++) {
                    this.question.answers[a]['letter_index'] = this.alphabet[a];
                    this.question.answers[a]['placeholder'] = 'Введите вариант ответа ' + this.alphabet[a];
                }
            },
            createAnswersArr() {
                for (let a = 0; a < 3; a++) {
                    this.question.answers.push({
                        letter_index: this.alphabet[a],
                        placeholder: 'Введите вариант ответа ' + this.alphabet[a],
                        value: ''
                    });
                }
            },
            submitModal() {
                let objToSend = {
                    question_id: (this.isEdit) ? this.question.question_id  : null,
                    name: this.question.message,
                    description: this.question.introduction_word,
                    is_radio: true,
                    color: "#4495CE",
                    topic_ids: this.question.topics.map((obj) => {return obj.id}),
                    answers: this.question.answers.filter((obj) => {return obj.value}).map((o) => {return o.value}),
                    to_user_ids: this.question.users.map((obj) => {return obj.id}),
                    is_comment_enabled: this.question.comment_option,
                    visible_for_id: this.question.visible_for_id,
                    image: null,
	                public_access: this.question.public_access
                };
                // console.log(objToSend);
	            let submMethod = () => {
		            this.submitted = true;
		            if (!this.isEdit) {
			            questionsService.createPersonalQuestion(objToSend).then((res) => {
				            if (res) {
					            // console.log(res);
					            this.$refs['create-personal-question'].hide();
					            this.$emit('refreshData');
				            }
				            this.submitted = false;
			            })
		            } else {
			            // console.log('EDIT');
			            questionsService.checkIfPQEditingAvailable(objToSend).then((res) => {
				            if (res) {
					            if (res.data.status === 'delete_and_create') {
						            let q = confirm('Your previous question will be deleted and new one created');
						            if (q) {
							            objToSend['status'] = 'delete_and_create';
							            questionsService.editPersonalQuestion(objToSend).then((res) => {
								            if (res) {
									            this.$refs['create-personal-question'].hide();
									            this.$emit('refreshData');
								            }
								            this.submitted = false;
							            })
						            }
					            } else if (res.data.status === 'change_targets') {
						            let q = confirm('Some of the users will be deleted, are you sure you wanna do this>?');
						            if (q) {
							            objToSend['status'] = 'change_targets';
							            objToSend['user_ids_for_delete'] = res.data.users.map((obj) => {
								            return obj.id
							            });
							            questionsService.editPersonalQuestion(objToSend).then((res) => {
								            if (res) {
									            // console.log(res);
									            this.$refs['create-personal-question'].hide();
									            this.$emit('refreshData');
								            }
								            this.submitted = false;
							            })
						            }
					            } else {
						            /*objToSend['status'] = 'updated';
									questionsService.editPersonalQuestion(objToSend).then((res) => {
										if (res) {
											// console.log(res);
											this.$refs['create-personal-question'].hide();
											this.$emit('refreshData');
										}
										this.submitted = false;
									});*/
						            this.$refs['create-personal-question'].hide();
						            this.$emit('refreshData');
					            }
					            /*this.$refs['create-personal-question'].hide();
								this.$emit('refreshData');
								this.submitted = false;*/
				            }
				            this.submitted = false;
			            })
		            }
				}
				
                if (this.isValid(objToSend)) {
                    // console.log('AAAAAAAAAAAAAAAA');
	                if (this.question.public_access) {
		                this.$refs['public-access-modal'].show({
			                message: 'Вы разрешили включать вопрос в общедоступный список. При этом варианты ответов могут быть изменены модераторами системы. Нажмите "Продолжить" для подтверждения данного разрешения и публикации вопроса',
			                okButton: 'Продолжить',
			                cancelButton: 'Отмена'
		                }).then((result) => {
			                console.log(result);
			                if (result) {
				                submMethod();
			                }
		                });
	                } else {
		                submMethod();
	                }
                }
            },
	
	        filteredList(data) {
		        console.log(data);
		        this.usersList.search_str = data.searchStr;
		        this.usersList.list = [];
		        this.usersList.page = 0;
		        this.getUsersForAskPersonalQuestion();
	        },
            getUsersForAskPersonalQuestion () {
                this.usersList.loading = true;
                questionsService.getUsersForAskQuestionAPI({
                    question_id: null/*this.question.question_id*/,
                    is_personal: 1,
	                page: (!this.usersList.page) ? 1 : this.usersList.page + 1,
	                user_name: this.usersList.search_str
                }).then(res => {
					if (res) {
						console.log(res);
						res.data.forEach((obj) => {
							this.usersList.list.push(obj);
						});
						
						this.usersList.loading = false;
						
						this.usersList.list.forEach(obj => {
							/*if (key === 'asked_users') {
								obj['selected'] = true;
								let index = this.question.users.findIndex((o) => {return o.id === obj.id});
								if (index === -1) {
									this.question.users.push(obj);
								}
							} else {
								obj['selected'] = false;
							}*/
							if (this.$route.query.user_id && this.$route.query.user_id === obj.id && !this.question.users.length) {
								obj['selected'] = true;
								let index = this.question.users.findIndex((o) => {return o.id === obj.id});
								if (index === -1) {
									this.question.users.push(obj);
								}
								this.$router.replace({'user_id': null});
							}
							obj['filtered'] = true;
							obj['avatar_parsed'] = null;
							// obj['selected'] = false;
							!obj['selected'] ? obj['selected'] = false : obj['selected'] = true;
							handleAWSImage(obj.avatar_36).then((res) => {
								obj['avatar_parsed'] = res;
								this.$forceUpdate();
							});
						});
						
						if (this.question.users.length) {
							this.question.users.forEach((o) => {
								this.usersList.list.forEach(obj => {
									if (o.selected) {
										if (obj.id === o.id) {
											obj['selected'] = true;
										}
									}
								});
							})
						}
						
						
						this.usersList.page = res.current_page
						this.usersList.last_page = res.last_page
					}
                    /*this.usersList = {
                        asked_users: [...res.asked_users],
                        chat_users: [...res.chat_users]
                    };
                    for (let key in this.usersList) {
                        if (this.usersList.hasOwnProperty(key)) {
                            this.usersList[key].forEach(obj => {
                                if (key === 'asked_users') {
                                    obj['selected'] = true;
                                    let index = this.question.users.findIndex((o) => {return o.id === obj.id});
                                    if (index === -1) {
                                        this.question.users.push(obj);
                                    }
                                } else {
                                    obj['selected'] = false;
                                }
                                if (this.$route.query.user_id && this.$route.query.user_id === obj.id && !this.question.users.length) {
                                    obj['selected'] = true;
                                    let index = this.question.users.findIndex((o) => {return o.id === obj.id});
                                    if (index === -1) {
                                        this.question.users.push(obj);
                                    }
                                    this.$router.replace({'user_id': null});
                                }
                                obj['filtered'] = true;
                                obj['avatar_parsed'] = null;
                                handleAWSImage(obj.avatar_36).then((res) => {
                                    obj['avatar_parsed'] = res;
                                    this.$forceUpdate();
                                });
                            });
                        }
                    }

                    if (this.question.users.length) {
                        this.question.users.forEach((o) => {
                            for (let key in this.usersList) {
                                if (this.usersList.hasOwnProperty(key)) {
                                    this.usersList[key].forEach(obj => {
                                        if (o.selected) {
                                            if (obj.id === o.id) {
                                                obj['selected'] = true;
                                            }
                                        }
                                    });
                                }
                            }
                        })
                    }*/
                });
            },
            selectUser(data) {
                data.selected = !data.selected;
                this.$forceUpdate();
            },
	        
            addUsersToQuestion(select_all) {
                if (select_all) {
                    if (this.isSelected().length === this.usersList.list.length) {
	                    this.usersList.list.forEach(obj => {
		                    obj['selected'] = false;
		                    this.$forceUpdate();
	                    });
                    } else {
	                    this.usersList.list.forEach(obj => {
		                    obj['selected'] = true;
		                    this.$forceUpdate();
	                    });
                    }
                } else {
                    this.question.users = [...this.isSelected()];
                    this.$refs['add-users'].hide();
                }

            },
            /*filteredUsersList(data) {
                this.searchStr = data.searchStr;
                if (data.searchStr.length) {
                    data.arr.forEach(obj => {
                        for (let key in this.usersList) {
                            if (this.usersList.hasOwnProperty(key)) {
                                this.usersList[key].forEach(c_obj => {
                                    if (c_obj.id === obj.id) {
                                        c_obj['filtered'] = true;
                                    } else {
                                        c_obj['filtered'] = false;
                                    }
                                    this.$forceUpdate()
                                });
                            }
                        }
                    });
                } else {
                    for (let key in this.usersList) {
                        if (this.usersList.hasOwnProperty(key)) {
                            this.usersList[key].forEach(c_obj => {
                                c_obj['filtered'] = true;
                                this.$forceUpdate()
                            });
                        }
                    }
                }

            },*/
            isSelected() {
                return this.usersList.list.filter((obj) => {return obj.selected})
            },

            toggleTopicsDropdown(e, toggle) {
                if (toggle) {
                    this.topics.show = !this.topics.show;
                } else {
                    this.topics.show = false;

                }
            },
            selectTopic(data) {
                if (data['selected']) {
                    data['selected'] = false;
                    let index = this.question.topics.findIndex((obj) => {return obj.id === data.id});
                    this.question.topics.splice(index, 1);
                    if (!this.question.topics.length) {
                        this.toggleTopicsDropdown(null, false)
                    }
                } else {
                    data['selected'] = true;
                    this.question.topics.push(data);
                    this.$forceUpdate();
                    if (this.question.topics.length === this.topics.list.length) {
                        this.toggleTopicsDropdown(null, false)
                    }
                }
                // console.log(this.question.topics);
            },

            onVisibilityChange(data) {
                this.question.visible_for_id = data;
                this.$forceUpdate();
            },
            isValid(objToSend) {
                return ((objToSend.name.length && objToSend.answers.length >= 2 && objToSend.topic_ids.length > 0) && (objToSend.visible_for_id === 1 || objToSend.visible_for_id === 2 && objToSend.to_user_ids.length > 0))
            },
	
	        showPublicAccessAlert() {
				console.log(' CHANGED ');
				console.log(this.question.public_access);
				/*if (this.question.public_access) {
					this.$refs['public-access-modal'].show({
						message: 'Вы разрешили включать вопрос в общедоступный список. При этом варианты ответов могут быть изменены модераторами системы. Нажмите "Продолжить" для подтверждения данного разрешения и публикации вопроса',
						okButton: 'Продолжить',
						cancelButton: 'Отмена'
					}).then((result) => {
						console.log(result);
						this.question.public_access = result;
					});
				}*/
	        }
        },
        computed: {
            topicsList() {
                return this.$store.state.question.topic_list_personal;
            },
            arr() {
                return [...this.usersList.asked_users, ...this.usersList.chat_users]
            }
        },
        beforeCreate() {},
        created() {
            console.log('created');
        },
        mounted() {
            console.log('mounted');
            this.editListener();
            this.createAnswersArr();
        },
        watch: {
            topicsList: function(val) {
                this.topics['list'] = [];
                val.forEach(obj => {
                    if (obj.id !== 9999999) {
                        this.topics['list'].push(obj);
                    }
                })
            },
            isSelected: function(val) {
                // console.log(val);
            }
        }

    }
</script>

<style lang="less">

    @import "../../../assets/styles/_vars.less";

    #create-personal-question {
        .modal-dialog {
            .modal-content {
                .modal-header {
                    .modal-title {
                        font-size: 13px!important;
                        color: #333;
                    }
                    .close {
                        color: #ffffff;
                    }
                }
                .modal-body {
                    padding: 0;
                    &>div {
                        margin: 0;
                        padding: 1em 1em;
                    }
	                .question-message-wrapper {
		                border-bottom: 1px solid #e0e3ea;
	                }
                    .invalid {
                        outline: 1px solid indianred;
                    }
                    .list-answers-wrapper {
                        padding: 0;
                        ul {
                            margin: 0;
                            padding: 0;
                            li {
                                width: 100%;
                                padding: 1em;
                                box-sizing: border-box;
                                border-bottom: 1px solid #e0e3ea;
                                list-style-type: none;
                                position: relative;
                                &:hover {
                                    .delete-answer-option {
                                        opacity: 1;
                                    }
                                }
                                &:last-child {
                                    border-bottom: none;
                                }
                                .letter_index {
                                    position: absolute;
                                    color: #999999;
                                }
                                input {
                                    &.active {
                                        padding-left: 1.5em;
                                    }
                                }
                                .delete-answer-option {
                                    opacity: 0;
                                    font-size: 12px;
                                    border: none;
                                    background-color: transparent;
                                    color: #ff6161;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    &:focus {
                                        outline:none;
                                    }
                                }

                            }
                        }
                    }
                    .divider {
                        .tips-for-question {
                            color: #7c7c7c;
                            font-size: 13px;
                            line-height: 15px;
                            letter-spacing: 0.1px;
                        }
                        &.gray-bg-row {
                            background: #f7f7f7;
                            border-top: 1px solid #e0e3ea;
                            border-bottom: 1px solid #e0e3ea;
                            .gray-bg-title {
                                color: @theme_color;
                                font-weight: 500;
                                font-size: 11px;
                                line-height: 13px;
                                text-transform: uppercase;
                                position: relative;
                                &.add-answer {
                                    padding-left: 30px;
                                    cursor: pointer;
                                    &:before {
                                        content: '+';
                                        color: @theme_color;
                                        font-size: 28px;
                                        position: absolute;
                                        top: -2px;
                                        left: 0;
                                    }
                                }
                            }
                        }
                    }
                    .users-wrapper {
                        position: relative;
                        cursor: pointer;
                        input {
                            padding-left: 28px;
                        }
                        .selected-users-wrapper {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-wrap: wrap;
                            margin-top: 10px;
                            .user {
                                text-align: center;
                                cursor: pointer;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;
                                padding: 5px 5px;
                                margin-bottom: 5px;
                                position: relative;
                                &.selected {
                                    background-color: #e0e0e0;
                                }
                                .image-as-bg {
                                    margin-right: 5px;
                                }
                                .name {
                                    font-weight: 500;
                                    font-size: 12px;
                                    color: #333333; // rgba(51, 51, 51, 0.5);
                                    overflow-wrap: break-word;
                                }
                                img {
                                    width: 20px;
                                    position: absolute;
                                    right: 20px;
                                    top: 50%;
                                    margin-top: -10px;
                                }
                            }
                        }
                        .add-users-btn {
                            padding-left: 28px;
                            cursor: pointer;
                            color: #9b9b9b;
                            font-size: 15px;
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            /*top: 50%;*/
                            top: 28px;
                            display: inline-block;
                            width: 19px;
                            height: 19px;
                            background: transparent url(../../../assets/img/icon.svg) center no-repeat;
                            background-size: contain;
                            transform: translateY(-50%);
                        }
                    }
                    .textarea-wrapper {
                        label {
                            display: none;
                        }
                        textarea {
                            border: none;
                            outline: none;
                            resize: none;
                            width: 100%;
                            height: 50px;
                            color: #333333;
                            font-size: 15px;
                            line-height: 15px;
                            letter-spacing: 0.1px;

                            &::placeholder {
                                font-size: 15px;
                                line-height: 15px;
                                letter-spacing: 0.1px;
                                color: #999999;
                            }
                        }
                    }
                    .topics-wrapper {
                        position: relative;
                        border-bottom: 1px solid #e0e3ea;
                        cursor: pointer;
                        margin-bottom: 1px;
                        .add-topics-btn {
                            padding-left: 28px;
                            font-size: 15px;
                            font-weight: 400;
                            color: #999999;
                        }
                        .selected-topics {
                            padding-left: 28px;
                            span {
                                font-size: 15px;
                                font-weight: 400;
                                color: #999999;
                                margin-right: 7px;
                                &:not(:last-child) {
                                    &:after {
                                        content: ','
                                    }
                                }
                            }
                        }
                        .topics-dropdown {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            background-color: white;
                            padding: 5px 10px;
                            color: #000;
                            width: 100%;
                            // height: 250px;
                            z-index: 20;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            flex-wrap: wrap;
                            .topic {
                                padding: 1px 10px;
                                border: 1px solid silver;
                                border-radius: 10px;
                                margin-right: 10px;
                                font-size: 12px;
                                color: #999999;
                                &.selected {
                                    background-color: #e0e0e0;
                                }
                            }
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            background: transparent url(../../../assets/img/tag.svg) center no-repeat;
                            background-size: contain;
                            transform: translateY(-50%);
                        }
                    }
                    input {
                        border: none;
                        outline: none;
                        width: 100%;
                        color: #333333;
                        font-size: 15px;
                        line-height: 15px;
                        &.active {
                            padding-left: 20px;
                        }
                        &::placeholder {
                            color: #999999;
                            font-size: 15px;
                            line-height: 15px;
                            letter-spacing: 0.1px;
                        }
                    }
                }
                .modal-footer {
                    background: #f7f7f7;
                    box-shadow: none;
                    justify-content: space-between;
                    border-top: 1px solid #e0e3ea;
                    .foo-left-col{
                        display: flex;
                        .btn-primary {
                            font-size: 10px;
                            line-height: 12px;
                            letter-spacing: normal;
                            margin-right: 20px;
                            padding-left: 25px;
                            padding-right: 25px;
                        }
                        .visible_for_id-wrapper {
                            display: flex;
                            align-items: center;
                            .text {
                                font-size: 12px;
                                color: #9b9b9b;
                            }
                        }
                    }
                    .comment-option {
                        display: flex;
                        align-items: flex-end;
                        padding-right: 20px;
	                    flex-wrap: wrap;
	                    flex-direction: column;
                        label {
                            font-size: 12px;
                            color: #9b9b9b;
                            margin-bottom: 0;
                            cursor: pointer;
                        }
                        .checkbox-container {
                            font-size: 12px;
                            margin-bottom: 0;
                            display: block;
                            position: relative;
                            cursor: pointer;
                            -webkit-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                            &.disabled {
                                opacity: 0.7;
                                cursor: not-allowed;
                            }
                            .custom-checkbox {
                                position: absolute;
                                opacity: 0;
                                cursor: pointer;
                                height: 0;
                                width: 0;
                                &:checked {
                                    &~.checkmark {
                                        &:after {
                                            display: block;
                                        }
                                    }
                                }
                            }
                            .checkmark {
                                position: absolute;
                                top: 0;
                                right: -20px;
                                height: 15px;
                                width: 15px;
                                background-color: white;
                                border: 2px solid #9ba6ba;
                                border-radius: 3px;
                                &:after {
                                    content: "";
                                    position: absolute;
                                    display: none;
                                    left: 3px;
                                    top: 0;
                                    width: 5px;
                                    height: 9px;
                                    border: solid #9ba6ba;
                                    border-width: 0 2px 2px 0;
                                    -webkit-transform: rotate(45deg);
                                    -ms-transform: rotate(45deg);
                                    transform: rotate(45deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #add-users, #share-photos {
        .modal-dialog {
            .modal-body {
                padding: 0;
                position: relative;
                .question-wrapper {
                    padding: 20px;
                    text-align: center;
                    display: flex;
                    min-height: 100px;
                    background: #5EBD9C;
                    justify-content: center;
                    align-items: center;
                    p {
                        font-size: 15px;
                        font-weight: 500;
                        line-height: 21px;
                        color: #000000;
                        margin-bottom: 0;
                    }
                }
                .users-list-wrapper {
                    padding: 20px;
                    max-height: 350px;
                    min-height: 350px;
                    overflow-y: auto;
                    &.loading {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    &::-webkit-scrollbar{
                        height: 4px;
                        width: 4px;
                        background: gray;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb{
                        background: lightgrey;
                        border-radius: 10px;
                    }
                    .users-list {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        flex-wrap: wrap;
                        .divider {
                            color: #666;
                            font-size: 11px;
                            font-weight: 500;
                            text-transform: uppercase;
                        }
                        .user {
                            text-align: center;
                            width: 100%;
                            cursor: pointer;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            padding: 5px 5px;
                            margin-bottom: 4px;
                            border-radius: 10px;
                            position: relative;
                            &.selected {
                                background-color: #e0e0e0;
                            }
                            .image-as-bg {
                                margin-right: 15px;
                            }
                            .name {
                                font-weight: 500;
                                font-size: 12px;
                                color: #333333; // rgba(51, 51, 51, 0.5);
                                overflow-wrap: break-word;
                            }
                            img {
                                width: 20px;
                                position: absolute;
                                right: 20px;
                                top: 50%;
                                margin-top: -10px;
                            }
                        }
	                    .def-btn {
		                    margin: 0 auto;
		                    font-size: 10px;
		                    padding: 2px;
		                    min-height: unset;
		                    text-transform: lowercase;
	                    }
                    }
                }
                .modal-controls {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    align-items: center;
                    background: #fff;
                    bottom: 0;
                    right: 0;
                    padding: 10px 20px;
                    justify-content: flex-end;
                    button {
                        &:last-child {
                            margin-left: 5px;
                        }
                        background-color: @theme_color;
                        color: #ffffff;
                        min-height: 32px;
                        min-width: 60px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-pack: center;
                        -ms-flex-pack: center;
                        justify-content: center;
                        -ms-flex-line-pack: center;
                        align-content: center;
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        border: none;
                        border-radius: 35px;
                        padding: 5px 15px;
                        -webkit-transition: all 250ms;
                        transition: all 250ms;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        cursor: pointer;
                        outline: none;
                        max-width: 130px;
                        width: 100%;
                        &:focus {
                            box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
                        }
                    }
                }
            }
        }
    }

    // Large devices (desktops, less than 1200px)
    @media (max-width: 1200px) {}
    // Medium devices (tablets, less than 992px)
    @media (max-width: 991px) {
	    #create-personal-question {
		    .modal-dialog {
			    .modal-content {
				    .modal-footer {
					    .comment-option {
						    width: 100%;
					    }
				    }
			    }
		    }
	    }
    }
    // Small devices (landscape phones, less than 768px)
    @media (max-width: 768px) {}
    // Extra small devices (portrait phones, less than 576px)
    @media (max-width: 576px) {}
    @media (max-width: 350px) {}
</style>
